/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useRef } from 'react';
import Swiper, { Pagination, Navigation, Autoplay } from 'swiper';
import { useAppearAnimation } from 'utils/appear-animation';
import { Icon } from 'components/atoms/icon';
import { mapModifiers, ModifierProp } from 'libs/component';

Swiper.use([Pagination, Navigation, Autoplay]);

export interface CarouselProps {
  children?: React.ReactNode;
  mode?: ModifierProp<'gallery' | 'news-highlight' | 'news' | 'surrounding' | 'hero'>;
  modifiers?: ModifierProp<'no-button'>;
  onSlideStartChange?: (instance: any) => void;
  onSlideChange?: (instance: any) => void;
  onSlideMove?: (instance: any, e: MouseEvent) => void;
  onSlideBounce?: (instance: any) => void;
  onTransitionStart?: (instance: any) => void;
  onTransitionEnd?: (instance: any) => void;
  onTouchStart?: (instance: any) => void;
  onTouchEnd?: (instance: any) => void;
  onAutoplay?: (instance: any) => void;
}

export const Carousel: React.FC<CarouselProps> = ({
  children,
  mode = 'gallery',
  modifiers,
  onSlideStartChange,
  onSlideChange,
  onSlideMove,
  onSlideBounce,
  onTransitionStart,
  onTransitionEnd,
  onTouchStart,
  onTouchEnd,
  onAutoplay,
}) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const carouselRef = useRef<HTMLDivElement>(null);
  const swiperRef = useRef(null);

  useEffect(() => {
    if (!swiperRef.current) {
      if (carouselRef.current) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const config: any = {
          preloadImages: false,
          lazy: {
            loadPrevNext: true,
          },
          slidesPerView: 1,
          loop: true,
          simulateTouch: true,
          spaceBetween: 22,
          speed: 700,
          pagination: {
            clickable: true,
            el: '.swiper-pagination',
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          watchOverflow: true,
        };
        if (mode === 'gallery') {
          config.autoplay = {
            delay: 5000,
            disableOnInteraction: false,
          };
          config.speed = 600;
          config.centeredSlides = true;
          config.slidesPerView = 'auto';
          config.spaceBetween = 14;
          config.breakpoints = {
            640: {
              slidesPerView: 'auto',
              spaceBetween: 40,
            },
            993: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
          };
        } else if (mode === 'news-highlight') {
          config.loop = false;
          config.centerInsufficientSlides = true;
          config.centeredSlides = true;
          config.centeredSlidesBounds = true;
          config.slidesPerView = 'auto';
          config.spaceBetween = 14;
          config.loopFillGroupWithBlank = true;
          // config.slidesPerView = 3;
          // config.slidesPerGroup = 3;
          config.breakpoints = {
            640: {
              slidesPerView: 'auto',
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              slidesPerGroup: 3,
              spaceBetween: 44,
            },
          };
        } else if (mode === 'news') {
          config.loop = false;
          config.centerInsufficientSlides = true;
          config.centeredSlides = true;
          config.centeredSlidesBounds = true;
          config.slidesPerView = 'auto';
          config.spaceBetween = 14;
          config.loopFillGroupWithBlank = true;
          config.breakpoints = {
            640: {
              slidesPerView: 'auto',
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              slidesPerGroup: 3,
              spaceBetween: 44,
            },
          };
          // config.navigation = false;
          // config.pagination = false;
        } else if (mode === 'surrounding') {
          config.loop = false;
          config.centeredSlides = true;
          config.slidesPerView = 'auto';
          config.spaceBetween = 16;
          config.breakpoints = {
            640: {
              slidesPerView: 'auto',
              spaceBetween: 20,
            },
            993: {
              slidesPerView: 'auto',
              spaceBetween: 44,
            },
          };
        } else if (mode === 'hero') {
          config.autoplay = {
            delay: 10000,
          };
          config.loop = true;
          config.centeredSlides = true;
          config.slidesPerView = 1;
          config.spaceBetween = 0;
        } else {
          config.breakpoints = {
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
          };
        }
        const swiper = new Swiper(carouselRef.current, config) as any;
        if (mode === 'news-highlight') {
          swiper.slideTo(1, false, false);
        }
        swiper.on('slideChangeTransitionStart', (inst: any) => {
          if (onSlideStartChange) {
            onSlideStartChange(inst);
          }
        });
        swiper.on('slideChangeTransitionEnd', (inst: any) => {
          if (onSlideChange) {
            onSlideChange(inst);
          }
        });
        swiper.on('sliderMove', (inst: any, e: MouseEvent) => {
          if (onSlideMove) {
            onSlideMove(inst, e);
          }
        });
        swiper.on('momentumBounce', (inst: any) => {
          if (onSlideBounce) {
            onSlideBounce(inst);
          }
        });
        swiper.on('transitionStart', (inst: any) => {
          if (onTransitionStart) {
            onTransitionStart(inst);
          }
        });
        swiper.on('transitionEnd', (inst: any) => {
          if (onTransitionEnd) {
            onTransitionEnd(inst);
          }
        });
        swiper.on('sliderMove', (inst: any, e: MouseEvent) => {
          if (onSlideMove) {
            onSlideMove(inst, e);
          }
        });
        swiper.on('touchStart', (inst: any) => {
          if (onTouchStart) {
            onTouchStart(inst);
          }
        });
        swiper.on('touchMove', (inst: any) => {
          if (onTouchStart) {
            onTouchStart(inst);
          }
        });
        swiper.on('touchEnd', (inst: any) => {
          if (onTouchEnd) {
            onTouchEnd(inst);
          }
        });
        swiper.on('autoplay', (inst: any) => {
          if (onAutoplay) {
            onAutoplay(inst);
          }
        });
        swiperRef.current = swiper;
      }
    }
  }, [
    mode,
    onAutoplay,
    onSlideBounce,
    onSlideChange,
    onSlideMove,
    onSlideStartChange,
    onTouchEnd,
    onTouchStart,
    onTransitionEnd,
    onTransitionStart,
  ]);

  const updateSliperSize = useCallback(() => {
    const swiper = swiperRef?.current as any;
    if (swiper) {
      swiper.update();
    }
  }, [swiperRef]);

  useEffect(() => {
    const rootEl: HTMLElement = rootRef?.current as any;
    if (rootEl) {
      rootEl.querySelectorAll('img').forEach((img: HTMLElement) => {
        img.addEventListener('load', updateSliperSize);
      });
    }
    return () => {
      if (rootEl) {
        rootEl.querySelectorAll('img').forEach((img: HTMLElement) => {
          img.removeEventListener('load', updateSliperSize);
        });
      }
    };
  });

  const { dataProps } = useAppearAnimation({ ref: rootRef });

  const containerClassName = `o-carousel__container ${React.Children.count(children) <= 1 ? 'swiper-no-swiping' : ''}`;

  return (
    <div ref={rootRef} className={mapModifiers('o-carousel', mode, modifiers)} {...dataProps}>
      <div ref={carouselRef} className={containerClassName}>
        <ul className="swiper-wrapper">
          {React.Children.map(children, (item, index) => {
            return (
              <li key={index} className="swiper-slide">
                {item}
              </li>
            );
          })}
        </ul>
        <div className="swiper-pagination" />
        <div className="swiper-button-prev">
          <Icon color="blue" name="chevron-left" />
        </div>
        <div className="swiper-button-next">
          <Icon color="blue" name="chevron-right" />
        </div>
      </div>
    </div>
  );
};
