import { RefObject, useEffect, useMemo } from 'react';
import { mapDataAttrs } from 'libs/component';
import { AppearAnimationDecorator } from './decorator';

interface UseAppearAnimationArgs {
  ref: RefObject<HTMLElement | SVGElement>;
  type?: 'fade-in-up' | 'custom';
  cssFeature?: 'transition' | 'animation';
  delay?: number;
  margin?: string;
}

export function useAppearAnimation({ ref, type = 'fade-in-up', cssFeature, delay, margin }: UseAppearAnimationArgs) {
  const dataProps = useMemo(() => {
    return mapDataAttrs({
      appearAnimationType: type,
      appearAnimationTriggered: 'false',
      appearAnimationFinished: 'false',
      appearAnimationCssFeature: cssFeature,
      appearAnimationDelay: delay?.toString(),
      appearAnimationMargin: margin,
    });
  }, [type, cssFeature, delay, margin]);

  useEffect(() => {
    if (!ref.current) {
      return () => undefined;
    }

    const decorator = new AppearAnimationDecorator({ el: ref.current, cssFeature, delay, margin });
    decorator.start();

    return () => decorator.stop();
  }, [ref, cssFeature, delay, margin]);

  return { dataProps };
}
