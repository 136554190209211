import React from 'react';
import { mapModifiers, ModifierProp } from 'libs/component';

export interface ImageProps {
  src: string;
  srcSp?: string;
  srcWebp?: string;
  srcWebpSp?: string;
  caption?: string;
  align?: ModifierProp<'center' | 'left' | 'right'>;
  width?: string;
  height?: string;
  lazy?: boolean;
  is360?: boolean;
}

export const Image: React.FC<ImageProps> = ({
  src,
  srcSp,
  srcWebpSp,
  srcWebp,
  caption,
  align = 'center',
  width,
  height,
  lazy = false,
  is360 = false,
}) => (
  <figure className={mapModifiers('a-image', align)}>
    {is360 && (
      <div className="a-image__icon-360">
        <img src="/images/360.svg" width="93" height="86" alt="360 icon" />
      </div>
    )}
    <div className="a-image__image-holder">
      <picture className="a-image__image">
        {srcSp && (
          <>
            {srcWebpSp && <source media="(max-width: 640px)" srcSet={srcWebpSp} type="image/webp" />}
            {srcWebp && <source media="(min-width: 641px)" srcSet={srcWebp} type="image/webp" />}
            <source media="(max-width: 640px)" srcSet={srcSp} />
            <source media="(min-width: 641px)" srcSet={src} />
          </>
        )}
        {lazy && <img data-src={src} alt={caption} width={width} height={height} className="lazyload" />}
        {!lazy && <img src={src} alt={caption} width={width} height={height} />}
      </picture>
    </div>
    {caption && <figcaption className={mapModifiers('a-image__caption')}>{caption}</figcaption>}
  </figure>
);
